<template>
	<div class="all_detail">
		<div class="detail">
			<div class="person_infor">
				<div class="person_infor_left">
					<div class="login_top">
						<div class="img"> <img src="@/assets/login/flag.png"></div>
						<div class="login_title"> {{$t('contactUs')}}</div>
					</div>
				</div>
			</div>
			<div class="all_lists">
				<div @click="itemClick(1)" class="list_item">
					WhatsApp：{{WhatsApp}}
				</div>
				<div @click="itemClick(2)" class="list_item">
					<span>
						{{$t('WechatNumber')}}：<span id="copy">{{weChat}}</span>
					</span>
					<span class="copy_text" @click="copyClick" data-clipboard-target="#copy">{{$t('copy')}}</span>
				</div>
				<div @click="itemClick(3)" class="list_item">
					{{$t('techniqueFeedback')}}：{{feedback}}
				</div>
				<div @click="itemClick(4)" class="list_item">
					{{$t('business')}}：{{business}}
				</div>
				<div @click="itemClick(5)" class="list_item">
					{{$t('complain')}}：{{complain}}
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				feedback: 'soporte@publichino.com', //技术反馈
				business: 'ventas@publichino.com', //商业
				WhatsApp: '507 64594186',
				weChat: 'PTY5566', //微信号
				complain: 'sugerencia@publichino.com', //投诉
			}
		},
		methods: {
			itemClick(val) {
				switch (val) {
					case 1:
						let whatsApp = '+' + this.WhatsApp;
						let whatsAppContactUrl = 'https://api.whatsapp.com/send?phone=' + whatsApp;
						window.open(whatsAppContactUrl);
						break;
					case 2:

						break;
					case 3:
						window.location.href = "mailto:" + this.feedback;
						break;
					case 4:
						window.location.href = "mailto:" + this.business;
						break;
					case 5:
						window.location.href = "mailto:" + this.complain;
						break;
				}
			},
			// 复制微信号
			copyClick() {
				var clipboard = new this.Clipboard('.copy_text');
				clipboard.on('success', e => {
					this.$message.success(this.$t('copySuccess'))
					// 释放内存  
					clipboard.destroy()
				})
			},
		}
	}
</script>

<style scoped lang="less">
	.all_detail {
		background: #F6F7F9;
		padding: 24px 0 64px;
	}

	.detail {
		box-sizing: border-box;
		width: 1200px;
		margin: 0 auto;
		min-height: 600px;
		background-color: #FFFFFF;
	}

	.person_infor {
		width: 926px;
	}

	.login_top {
		width: 1200px;
		display: flex;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;

		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;
		}
	}

	.all_lists {
		text-align: left;
		padding: 32px 32px;

		.list_item {
			cursor: pointer;
			padding: 12px 0;
		}
	}


	.copy_text {
		color: #4177DD;
		margin-left: 12px;
	}
</style>
